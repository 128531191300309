<template>
  <button
      :title="text"
      type="button"
      class="primary-button"
      :disabled="disabled"
  >
    <span>{{ text }}</span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.primary-button {
  @apply
  flex
  items-center
  justify-between
  space-x-2
  w-max
  bg-primary-light
  dark:bg-primary-dark
  text-white
  dark:text-salat-text
  rounded-full
  font-thin
  normal-case
  px-5
  py-2
}

.primary-button:disabled {
  @apply
  text-gray-300
  bg-gray-50
  cursor-not-allowed
}
</style>
